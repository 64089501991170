<template>
  <div class="pa-2 d-flex row justify-space-between">
    <div>
      <h4 class="text-dark">{{ title }}</h4>
    </div>
    <div class="d-flex justify-center align-end">
      <v-btn depressed  @click="closeModalDialog()" color="white">
        <span class="flex center text-danger"
          ><v-icon>mdi-close</v-icon>
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalPageTitle",
  props: {
    title: String,
    closeModalDialog: Function,
  },
};
</script>

<style>
</style>