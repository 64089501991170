<template>
  <v-layout>
    <v-dialog v-model="projectAddDialogData" max-width="60%" persistent>
      <v-card>
        <v-card-title>
          <modal-page-title :title="pageTitle" :closeModalDialog="addProjectDialog"></modal-page-title>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            :counter="10"
            label="Name"
            prepend-inner-icon="mdi-text"
            required
          ></v-text-field>

          <v-text-field
            v-model="first"
            label="Descriptions"
            prepend-inner-icon="mdi-text"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="pink darken-1"
            class="white--text"
            small
            @click="addProjectDialog(false)"
          >
            Cancel
          </v-btn> -->
          <v-btn
            color="success darken-1"
            small
            class="white--text"
            @click="addProjectDialog(false)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import modalPageTitle from "../../../digitaltp_shared/modal-page-title .vue";
export default {
  components: { modalPageTitle },
  name: "ProjectAdd",
  props: {
    addProjectDialog: Function,
    projectAddDialogData: Boolean,
  },
  data() {
    return {
      pageTitle: "New Project",
    };
  },
};
</script>

<style>
</style>