<template>
  <v-card flat class="full-width" max-width="100%">
    <v-simple-table>
      <template>
        <tbody>
          <tr>
            <td>Title</td>
            <td>
              <h3 class="text-dark">Digital Ecosystem</h3>
            </td>
          </tr>
          <tr>
            <td>Descriptions</td>
            <td>
              <div class="pa-2">
                Help lay the groundwork for growth of the digital economy by
                strengthening the many interrelated elements that characterize a
                thriving digital ecosystem by
              </div>
              <div class="pa-2">
                <ul>
                  <li>
                    Creating and implementing forward-looking laws, regulations
                    and policies;
                  </li>
                  <li>
                    Building digital skills and capacity of institutions and
                    youth
                  </li>
                  <li>
                    Developing a critical mass of innovators, entrepreneurs, and
                    support services; and developing a robust local ICT industry
                    that is founded on private investment and is able to deliver
                    e-Commerce services, and working toward closing the digital
                    divide
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="questionadd" max-width="800px">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          <v-icon>mdi-plus</v-icon> Add Video Meeting
        </v-card-title>
        <v-form class="px-3">
          <v-card-text>
            <v-text-field
              autofocus
              label="Meeting URL"
              prepend-icon="mdi-link-plus"
            ></v-text-field>
            <small class="grey--text">* Ivalid Video Meeting URL</small>
          </v-card-text>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small fab color="pink" @click="questionadd = !questionadd">
            <v-icon class="white--text">mdi-close</v-icon>
          </v-btn>

          <v-btn small fab color="primary">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  props: ["appeal"],
  data() {
    return {
      OpenNoticeDialog: false,
      OpenfileDialog: false,
      OpenDecisionEditor: false,
      filePreviewDialog: false,
      OpenPSARecommendationEditor: false,
      questionadd: false,
      decided: true,
      title: "",
      actionName: "PSA Analysis, Comments and Advice",
      appealTitle: "PSA Analysis, Comments and Advice",
      name: [],
    };
  },
};
</script>

<style>
</style>